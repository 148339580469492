import { Menu, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import translations from '../../context/Translations';
import logo from '../../images/aceva.png';
import SelectLanguage from './SelectLanguage';

export default function HeaderMenu() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const { language } = useLanguage();
  const t = translations[language];

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial scroll position
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuItems = [
    { path: '/how-it-works', label: t?.menu?.howIsItWorks, hash: '#how-it-works' },
    // { path: '/live-demo', label: t?.menu?.demo, hash: '#live-demo' },
    { path: '/pricing', label: t?.menu?.pricing }
  ];

  return (
    <header className={`sticky top-0 left-0 right-0 z-50 bg-white transition-all duration-300 flex !pb-1 h-16 sm:h-20 md:h-20 lg:h-20 xl:h-20 justify-start items-center ${isSticky ? 'shadow-md' : ''}`}>
      {/* desktop header menu */}
      <nav className="container mx-auto">
        
        {/* wrapper */}
        <div className='flex items-center justify-between'>
          {/* left menu */}
          <div className='flex justify-start items-center gap-6 md:gap-10 w-full'>
            <Link to="/" className="flex items-center">
              <img src={logo} alt="Aceva Logo" className="h-10 md:h-10 w-auto" />
            </Link>

            <div className="hidden md:flex items-center space-x-6">
              {menuItems?.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className={`text-gray-600 hover:text-brand-color transition-colors text-base font-medium ${location.pathname === item.path ? '!text-brand-color' : ''}`}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
            {/* right menu */}
          <div className="hidden md:flex items-center justify-end gap-3 w-full">
            <Link to="/login" className="block">
              <button className='text-brand-color-bold hover:text-brand-color-bold font-medium transition-colors border border-gray-200 px-4 py-2' style={{border: '1px solid #009688', color: '#009688', borderRadius: '6px'}}>
                {t?.menu?.signIn}
              </button>
            </Link>
            <Link
              to="/register"
              className="bg-brand-color text-white font-medium px-4 py-2 !rounded hover:bg-opacity-90 transition-colors hover:text-white block" style={{border: '1px solid transparent'}}
            >
                {t?.menu?.button}
            </Link>
              <Link
                to="/contact-us"
                className="bg-brand-color text-white font-medium px-4 py-2 !rounded hover:bg-opacity-90 transition-colors hover:text-white block" style={{border: '1px solid transparent'}}
              >
                {t?.menu?.contactUs}
              </Link>
            <SelectLanguage />
          </div>

          {/* extra mobile toggle button */}
          <button
          className="md:hidden text-gray-600 hover:text-brand-color transition-colors"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
        </div>

        
      </nav>

      {/* Mobile Header Menu */}
      <div
        className={`fixed inset-0 bg-white z-50 transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out md:hidden overflow-y-auto`}
      >
        <div className="px-4 py-6 flex flex-col min-h-screen">
          <div className="flex justify-between items-center mb-8 border-b border-gray-100 pb-4">
            <Link to="/" className="flex items-center" onClick={() => setMenuOpen(false)}>
              <img src={logo} alt="Aceva Logo" className="h-8 w-auto" />
            </Link>
            <div className='flex justify-end items-center gap-3'>
              <SelectLanguage />
              <button
                className="text-gray-600 hover:text-brand-color transition-colors"
                onClick={toggleMenu}
                aria-label="Close Menu"
              >
                <X size={24} />
              </button>
            </div>
          </div>

          <nav className="flex-grow">
            <ul className="space-y-6">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.path}
                    className={`block text-lg text-gray-600 hover:text-brand-color transition-colors ${location.pathname === item.path ? '!text-brand-color font-medium' : ''}`}
                    onClick={() => setMenuOpen(false)}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <div className="mt-8 space-y-4">
            <Link
              to="/login"
              className="block w-full text-center text-gray-600 hover:text-brand-color transition-colors bg-gray-50 px-4 py-3 rounded-md font-medium text-base hover:bg-brand-color-light"
              onClick={() => setMenuOpen(false)}
            >
              {t?.menu?.signIn}
            </Link>
            <Link
              to="/register"
              className="block w-full text-center bg-brand-color px-4 py-3 rounded-lg hover:bg-brand-color-bold text-white transition-colors hover:text-white font-medium text-base"
              onClick={() => setMenuOpen(false)}
            >
              {t?.menu?.button}
            </Link>
            <Link
              to="/contact-us"
              className="block w-full text-center bg-brand-color px-4 py-3 rounded-lg hover:bg-brand-color-bold text-white transition-colors hover:text-white font-medium text-base"
              onClick={() => setMenuOpen(false)}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}