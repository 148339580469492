import React from 'react'
import { Link } from 'react-router-dom'
import { useLanguage } from '../../context/LanguageContext'
import translations from '../../context/Translations'
import logo from '../../images/aceva.png'

function FooterMenu({bg}) {

    const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <div className={bg === "gray" ? `footer-wrapper --bg` : `footer-wrapper`}>
          <Link to="/">
            <div className="footer-logo">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <div className="footer-menu">
            <ul className='landing-page-menu footer-menu-wrap'>
              <li>
                <Link to="/pricing">{t?.footer?.menu?.pricing}</Link>
              </li>
              <li>
                <Link to="/contact-us">{t?.footer?.menu?.contactUs}</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">{t?.footer?.menu?.termsConditions}</Link>
              </li>
              <li>
                <Link to="/privacy-policy">{t?.footer?.menu?.privacyPolicy}</Link>
              </li>
              {/* <li>
                <Link to="/blog">{t?.footer?.menu?.blog}</Link>
              </li> */}
            </ul>
          </div>
          <div className="footer-bottom-info">
            <p>
              <a href={`mailto:info@wipdata.com`}>info@wipdata.com</a>
            </p>
          </div>
        </div>
    </>
  )
}

export default FooterMenu