
export const apiUrl = 'https://devapi.aceva.com.my/api';
export const fileUrl = 'https://api2.aceva.com.my/api/upload';
export const rootUrl = 'https://api2.aceva.com.my';
// export const apiUrl = "http://localhost:5000/api";

const baseUrl = 'https://devapi.aceva.com.my';

export const chatUrl = `${baseUrl}/api/botInteract/chat`;
export const chatMyUrl = `${baseUrl}/api/botInteract/chat_my`;
export const getChatByUserId = `${baseUrl}/api/botInteract`;
export const symptomsUrl = `${baseUrl}/api/symptoms`;
export const kpisUrl = `${baseUrl}/api/appointment/kpis`;
export const notifications = `${baseUrl}/api/notifications`;
export const LogoutDuration = 60*60*1000*2

//stripe plan parice
export const basic = 'price_1QWXmlFBC3QMoaI4CA5ZMmp2'
export const pro = 'price_1QWXn7FBC3QMoaI4i8bHavIk'

//stripe addon price
export const RemoveACEVABrandingAddon = 'price_1QWXlSFBC3QMoaI4OjoYEMAh'
export const ChatbotAddon = 'price_1QWXlhFBC3QMoaI4bqMW8aEW'
export const MessagesAddon = 'price_1QWXlBFBC3QMoaI4Pzzh9kgG'
export const WhatsappAddon = 'price_1QWXkgFBC3QMoaI4x0Wz83yr'
export const BuildItYourSelf = 'price_1QWXmIFBC3QMoaI4jEz59DCo'